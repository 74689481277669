import SEO from "../components/SEO"
import { Link } from "gatsby"
import { Fragment } from "react"

const NotFoundPage = () => (
  <Fragment>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      Go to <Link to="/">Homepage</Link>
    </p>
  </Fragment>
)

export default NotFoundPage
